import router from './router'
import { getAccessToken } from '@/utils/auth'

router.beforeEach((to, from, next) => {
  const needAuth = to.meta.needAuth
  const token = getAccessToken()
  if (token != null) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (needAuth) {
      next('/login')
    } else {
      next()
    }
  }
})
