// 稿件
const Post = () => import('views/post/Post')
const History = () => import('views/post/History')
const PostAlbum = () => import('views/post/PostAlbum')
const PostAnalysis = () => import('views/post/PostAnalysis')
const PostPublishFile = () => import('components/upload/PublishFile')
const VideoPostList = () => import('views/post/VideoPost')
const VideoPostPublish = () => import('views/post/VideoPostPublish')
const VideoPostEdit = () => import('views/post/VideoPostEdit')
const PostPublishAudio = () => import('components/upload/PublishAudio')
const UserPostAudio = () => import('views/post/AudioPost')
const PostEditAudio = () => import('components/upload/EditAudio')
const PostPublishAlbum = () => import('components/upload/PublishImage')
const UserPostImage = () => import('views/post/ImagePost')
const ImagePostEdit = () => import('views/post/ImagePostEdit')
const PostPublishArticle = () => import('components/upload/PublishArticle')
const UserPostArticle = () => import('views/post/ArticlePost')
const PostEditArticle = () => import('components/upload/EditArticle')

export default {
  path: '/post',
  name: 'Post',
  component: Post,
  meta: { needAuth: true },
  children: [
    {
      path: '',
      name: 'PostIndex',
      component: PostAnalysis,
      meta: { needAuth: true }
    },
    // ********************************************************************************************************************
    {
      path: '/post/publish/audio',
      name: '发布音频',
      component: PostPublishAudio,
      meta: { needAuth: true }
    },
    {
      path: '/post/publish/article',
      name: '发布文章',
      component: PostPublishArticle,
      meta: { needAuth: true }
    },
    {
      path: '/post/list/audio',
      name: '音频稿件',
      component: UserPostAudio,
      meta: { needAuth: true }
    },
    {
      path: '/post/list/article',
      name: '文章稿件',
      component: UserPostArticle,
      meta: { needAuth: true }
    },
    {
      path: '/post/edit/audio/:audioId',
      name: 'PostEditAudio',
      component: PostEditAudio,
      meta: { needAuth: true }
    },
    {
      path: '/post/edit/article/:articleId',
      name: 'PostEditArticle',
      component: PostEditArticle,
      meta: { needAuth: true }
    },
    // ********************************************************************************************************************
    {
      path: '/post/video/file',
      name: '发布文件',
      component: PostPublishFile,
      meta: { needAuth: true }
    },
    {
      path: '/post/video/publish',
      name: '发布视频',
      component: VideoPostPublish,
      meta: { needAuth: true }
    },
    {
      path: '/post/video/list',
      name: '视频列表',
      component: VideoPostList,
      meta: { needAuth: true }
    },
    {
      path: '/post/list/video',
      name: '视频稿件',
      component: VideoPostList,
      meta: { needAuth: true }
    },
    {
      path: '/post/image/list',
      name: '图片稿件',
      component: UserPostImage,
      meta: { needAuth: true }
    },
    {
      path: '/post/video/edit/:videoId',
      name: 'VideoPostEdit',
      component: VideoPostEdit,
      meta: { needAuth: true }
    },
    {
      path: '/post/publish/video',
      name: '发布视频',
      component: VideoPostPublish,
      meta: { needAuth: true }
    },
    {
      path: '/post/history',
      name: '历史记录',
      component: History,
      meta: { needAuth: true }
    },
    // ********************************************************************************************************************
    {
      path: '/post/album/publish',
      name: '发布相册',
      component: PostPublishAlbum,
      meta: { needAuth: true }
    },
    {
      path: '/post/album/list',
      name: '稿件合集',
      component: UserPostImage,
      meta: { needAuth: true }
    },
    {
      path: '/post/image/edit/:albumId',
      name: 'ImagePostEdit',
      component: ImagePostEdit,
      meta: { needAuth: true }
    },
    {
      path: '/post/list/image',
      name: '相册稿件',
      component: UserPostImage,
      meta: { needAuth: true }
    },
    {
      path: '/post/publish/image',
      name: '发布相册',
      component: PostPublishAlbum,
      meta: { needAuth: true }
    },
    // ********************************************************************************************************************
    {
      path: '/post/album',
      name: 'PostAlbum',
      component: PostAlbum,
      meta: { needAuth: true }
    }
  ]
}
