// ********************************************************************************************************************
// 网盘主页
// ********************************************************************************************************************
const MapIndex = () => import('views/map/MapIndex')
const AMap = () => import('views/map/AMap')
const PhotoMap = () => import('views/map/PhotoMap')
const TrailMap = () => import('views/map/TrailMap')

export default {
  path: '/map',
  name: 'MapIndex',
  component: MapIndex,
  meta: { needAuth: true },
  children: [
    {
      path: '',
      name: '地图',
      component: AMap,
      meta: { needAuth: true }
    },
    {
      path: '/map/photo',
      name: '照片地图',
      component: PhotoMap,
      meta: { needAuth: true }
    },
    {
      path: '/map/trail',
      name: '轨迹地图',
      component: TrailMap,
      meta: { needAuth: true }
    }
  ]
}
