// 根级别的 state
const state = {
  socketStatus: 'Offline',
  pageBean: Object,
  activePage: 1,
  cid: 1,
  value: String // 搜索框的value
}

export default state
