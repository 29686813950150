const Index = () => import('views/Index')
// ********************************************************************************************************************
// 用户前台主页
// ********************************************************************************************************************
const UserHome = () => import('views/user/Home')
const UserVideo = () => import('views/user/Home')
const UserImage = () => import('views/user/Home')
const UserAlbum = () => import('views/user/Home')
const UserRelation = () => import('views/user/UserRelation')

export default {
  path: '/user',
  name: 'Index',
  component: Index,
  meta: { needAuth: false },
  children: [
    {
      path: '',
      name: 'User',
      component: () => import('@/views/404.vue'),
      meta: { needAuth: false }
    },
    {
      path: '/user/:id',
      name: 'UserHome',
      component: UserHome,
      meta: { needAuth: false }
    },
    {
      path: '/user/:id/video',
      name: 'UserVideo',
      component: UserVideo,
      meta: { needAuth: false }
    },
    {
      path: '/user/:id/image',
      name: 'UserImage',
      component: UserImage,
      meta: { needAuth: false }
    },
    {
      path: '/user/:id/album',
      name: 'UserAlbum',
      component: UserAlbum,
      meta: { needAuth: false }
    },
    {
      path: '/user/:id/following',
      name: 'UserRelation',
      component: UserRelation,
      meta: { needAuth: false }
    },
    {
      path: '/user/:id/follower',
      name: 'UserRelation',
      component: UserRelation,
      meta: { needAuth: false }
    }
  ]
}
