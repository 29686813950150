const Talk = {
  state: {
    // 会话列表
    items: []
  },
  getters: {
    talkItems: state => {
      return state.items
    },
    talkNum: state => state.items.length
  },
  mutations: {
    // 新增对话节点
    PUSH_TALK_ITEM(state, resource) {
      state.items.push(resource)
    },
    // 移除对话节点
    REMOVE_TALK_ITEM(state) {
      state.items = []
    },
    SET_LOAD_STATUS(state, resource) {
      state.loadStatus = resource
    },
    // 设置对话列表
    SET_TALK_ITEMS(state, resource) {
      state.items = resource.items
    },
    // 更新对话节点
    UPDATE_TALK_ITEM(state, resource) {
      for (const iterator of state.items) {
        if (iterator.indexName === resource.indexName) {
          Object.assign(iterator, resource)
          break
        }
      }
    },
    // 更新对话消息
    UPDATE_TALK_MESSAGE(state, resource) {
      for (const iterator of state.items) {
        if (iterator.indexName !== resource.indexName) {
          continue
        }

        iterator.unreadNum++
        iterator.msgText = resource.msgText
        iterator.updatedAt = resource.updatedAt
        break
      }
    },

    SET_TLAK_UNREAD_MESSAGE(state, resource) {
      state.unreadMessage.num++
      state.unreadMessage.nickname = resource.nickname
      state.unreadMessage.content = resource.content
    },

    // 清除最后一条未读消息
    CLEAR_TLAK_UNREAD_MESSAGE(state) {
      state.unreadMessage = {
        num: 0,
        nickname: '未知',
        content: '...'
      }
    }
  },
  actions: {
    // 加载会话列表
    LOAD_TALK_ITEMS(context) {
      context.commit('SET_LOAD_STATUS', 2)
      /* ServeGetTalkList().then(({ code, data }) => {
          if (code !== 0) return

          context.commit('SET_TALK_ITEMS', {
            items: data.map(item => formateTalkItem(item)),
          })

          context.commit('SET_LOAD_STATUS', 3)
        })
        .catch(() => {
          context.commit('SET_LOAD_STATUS', 4)
        })*/
    }
  }
}

export default Talk
