import Vuex from 'vuex'
import Vue from 'vue'

import user from './modules/user'
import chat from './modules/talk'
import getters from './getters'
import state from './state'
import mutations from './mutations'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user,
    chat
  },
  getters,
  state,
  mutations,
  // 异步操作
  actions: {
    getPageBean(context) {
      /* getPageBean(context.state.cid, context.state.activePage, 6).then(res => {
        context.commit('getPageBean', res)
      })*/
    },
    getPageBeanByValue(context) {
      const res = {
        totalCount: 10,
        list: [
          {
            vname: '哈1',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈2',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈3',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈4',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈5',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈1',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈2',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈3',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈4',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          },
          {
            vname: '哈5',
            coverurl: 'https://picx.zhimg.com/v2-ea15e9203a1d95a465a78da043a9315d_xl.jpg',
            releasetime: '2023-04-24 16:47:00',
            visited: 10,
            keyword: '芒果,草莓,西瓜'
          }
        ]
      }
      context.commit('getPageBean', res)
      /* getPageBeanByValue(context.state.value, context.state.activePage, 8).then(res => {
        console.log(res)
        context.commit('getPageBean', res)
      })*/
    }
  }
})

export default store
