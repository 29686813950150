// 根级别的 mutation
const mutations = {
  // 更新socket 连接状态
  UPDATE_SOCKET_STATUS(state, status) {
    state.socketStatus = status
  },
  // 获取分页视频
  getPageBean(state, pageBean) {
    state.pageBean = pageBean
  },
  // 存入cid
  saveCid(state, cid) {
    state.cid = cid
  },
  // 存入value
  saveValue(state, value) {
    state.value = value
  },
  // 存入playerVideo
  savePlayerVideo(state, palyerVideo) {
    state.playerVideo = palyerVideo
  },
  // 改变页数
  updatePage(state, newPage) {
    state.activePage = newPage
  }
}

export default mutations
