// ********************************************************************************************************************
// 后台主页
// ********************************************************************************************************************
const ExamIndex = () => import('views/exam/ExamIndex')
const Exam = () => import('views/exam/Exam')
const ExamDashboard = () => import('views/exam/ExamDashboard')
const ExamList = () => import('views/exam/ExamList')
const ExamCard = () => import('views/exam/ExamCard')
const ExamSubject = () => import('views/exam/ExamSubject')
const ExamQuestion = () => import('views/exam/ExamQuestion')
const ExamQuestionAdd = () => import('views/exam/ExamQuestionAdd')
const ExamPaper = () => import('views/exam/ExamPaper')
const ExamPaperAdd = () => import('views/exam/ExamPaperAdd')
const ExamPaperPreview = () => import('views/exam/ExamPaperPreview')
const ExamScoreIndex = () => import('views/exam/ExamScoreIndex')
const ExamPaperResult = () => import('views/exam/ExamPaperResult')
const ExamMarkIndex = () => import('views/exam/ExamMarkIndex')

export default {
  path: '/exam',
  name: 'Exam',
  component: Exam,
  meta: { needAuth: true },
  children: [
    {
      path: '',
      name: 'ExamIndex',
      component: ExamIndex,
      meta: { needAuth: true }
    },
    {
      path: '/exam/start/:paperId',
      name: 'ExamCard',
      component: ExamCard,
      meta: { needAuth: true }
    },
    {
      path: '/exam/starts/:paperId',
      name: 'ExamPaperPreview',
      component: ExamPaperPreview,
      meta: { needAuth: true }
    },
    {
      path: '/exam/score',
      name: 'ExamScoreIndex',
      component: ExamScoreIndex,
      meta: { needAuth: true }
    },
    {
      path: '/exam/score/:resultId',
      name: 'ExamScorePage',
      component: ExamPaperResult,
      meta: { needAuth: true }
    },
    {
      path: '/exam/mark',
      name: 'ExamMarkIndex',
      component: ExamMarkIndex,
      meta: { needAuth: true }
    },
    {
      path: '/exam/mark/:resultId',
      name: 'ExamMarkPage',
      component: ExamPaperResult,
      meta: { needAuth: true }
    },
    {
      path: '/exam/subject',
      name: 'ExamSubject',
      component: ExamSubject,
      meta: { needAuth: true }
    },
    {
      path: '/exam/question',
      name: 'ExamQuestion',
      component: ExamQuestion,
      meta: { needAuth: true }
    },
    {
      path: '/exam/question/add',
      name: 'ExamQuestionAdd',
      component: ExamQuestionAdd,
      meta: { needAuth: true }
    },
    {
      path: '/exam/paper',
      name: 'ExamPaper',
      component: ExamPaper,
      meta: { needAuth: true }
    },
    {
      path: '/exam/paper/add',
      name: 'ExamPaperAdd',
      component: ExamPaperAdd,
      meta: { needAuth: true }
    },
    {
      path: '/exam/preview/:paperId',
      name: 'ExamPaperPreview',
      component: ExamPaperPreview,
      meta: { needAuth: true }
    },
    {
      path: '/exam/list',
      name: 'ExamList',
      component: ExamList,
      meta: { needAuth: true }
    },
    {
      path: '/exam/statistics',
      name: 'ExamDashboard',
      component: ExamDashboard,
      meta: { needAuth: true }
    }
  ]
}
