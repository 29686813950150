const USER_ACCESS_TOKEN = 'ACCESS-TOKEN'
const USER_REFRESH_TOKEN = 'REFRESH-TOKEN'
const USER_INFO = 'TNB-USERINFO'

/**
 * 设置已登录用户的 token
 *
 * @param {Object} userToken
 */
export function setUserToken(userToken) {
  const accessToken = userToken.accessToken
  const accessExpireAt = userToken.accessExpireAt
  localStorage.setItem(USER_ACCESS_TOKEN,
    JSON.stringify({
      accessToken,
      accessExpireAt
    })
  )

  const refreshToken = userToken.refreshToken
  const refreshExpireAt = userToken.refreshExpireAt
  localStorage.setItem(USER_REFRESH_TOKEN,
    JSON.stringify({
      refreshToken,
      refreshExpireAt
    })
  )
}

/**
 * 获取已登录用户的访问令牌
 */
export function getAccessToken() {
  const result = localStorage.getItem(USER_ACCESS_TOKEN)
  if (result !== undefined && result !== null) {
    const token = JSON.parse(result)
    const t = new Date().getTime()
    if (token.accessExpireAt > t) {
      return token.accessToken
    }

    localStorage.removeItem(USER_ACCESS_TOKEN)
  }
  return null
}

/**
 * 获取已登录的刷新令牌
 */
export function getRefreshToken() {
  const result = localStorage.getItem(USER_REFRESH_TOKEN)
  if (result !== undefined && result !== null) {
    const token = JSON.parse(result)
    const t = new Date().getTime()
    if (token.refreshExpireAt > t) {
      return token.refreshToken
    }

    localStorage.removeItem(USER_REFRESH_TOKEN)
  }
  return null
}

/**
 * 设置已登录用户信息
 *
 * @param {Object} data
 */
export function setAuthedUser(data) {
  localStorage.setItem(USER_INFO, JSON.stringify(data))
}

/**
 * 更新已登录用户信息
 *
 * @param {Object} data
 */
export function updateAuthedUser(data) {
  localStorage.setItem(USER_INFO, JSON.stringify(data))
}

/**
 * 获取已登录用户信息
 */
export function getAuthedUser() {
  const data = localStorage.getItem(USER_INFO) || null
  return data !== null ? JSON.parse(data) : null
}

/**
 * 删除已登录用户相关缓存信息
 */
export function removeAll() {
  localStorage.removeItem(USER_ACCESS_TOKEN)
  localStorage.removeItem(USER_REFRESH_TOKEN)
  localStorage.removeItem(USER_INFO)
  console.log('删除 localstorage')
}
