// ********************************************************************************************************************
// 商城
// ********************************************************************************************************************
const Mall = () => import('views/mall/Mall')
const Overview = () => import('views/mall/Overview')
const Cart = () => import('views/mall/Cart')
const Fav = () => import('views/mall/Fav')
const Order = () => import('views/mall/Order')
const ConfirmOrder = () => import('views/mall/ConfirmOrder')
const Visit = () => import('views/mall/Visit')
const Pay = () => import('views/mall/Pay')
const Product = () => import('views/mall/Product')
const MyProduct = () => import('views/mall/MyProduct')

export default {
  path: '/mall',
  name: 'Mall',
  component: Mall,
  meta: { needAuth: false },
  children: [
    {
      path: '',
      name: '概览',
      component: Overview,
      meta: { needAuth: false }
    },
    {
      path: '/mall/cart',
      name: '购物车',
      component: Cart,
      meta: { needAuth: false }
    },
    {
      path: '/mall/fav',
      name: '收藏夹',
      component: Fav,
      meta: { needAuth: false }
    },
    {
      path: '/mall/order',
      name: '订单',
      component: Order,
      meta: { needAuth: false }
    },
    {
      path: '/mall/order/confirm',
      name: 'ConfirmOrder',
      component: ConfirmOrder,
      meta: { needAuth: false }
    },
    {
      path: '/mall/visit',
      name: '浏览记录',
      component: Visit,
      meta: { needAuth: false }
    },
    {
      path: '/mall/pay',
      name: 'Pay',
      component: Pay,
      meta: { needAuth: false }
    },
    {
      path: '/mall/item',
      name: 'Product',
      component: Product,
      meta: { needAuth: false }
    },
    {
      path: '/mall/myproduct',
      name: 'MyProduct',
      component: MyProduct,
      meta: { needAuth: false }
    }
  ]
}
