import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import ElementUI from 'element-ui'
// Element 额外提供了一系列类名，用于在某些条件下隐藏元素
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
Vue.use(ElementUI)

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

import uploader from 'vue-simple-uploader'
Vue.use(uploader)

import AudioPlayer from '@liripeng/vue-audio-player'
Vue.use(AudioPlayer)

import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards)

// 先引入 MathJax 配置, 再从 mathjax 包中引入 tex-mml-chtml
// 这里不使用 mathjax 的 npm 包, 而是使用 CDN 引入
import MathJax from './assets/js/mathjax'
// import 'mathjax/es5/tex-mml-chtml'
Vue.prototype.MathJax = MathJax

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import '@/permission'

Vue.config.productionTip = false // 阻止控制台打印生产模式下的消息
Vue.prototype.baseURL = process.env.VUE_APP_SERVER_URL
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
