import { setAuthedUser, getAuthedUser, getAccessToken } from '@/utils/auth'

const state = {
  userId: 0,
  username: null,
  avatarUrl: null
}

// 判断用户是否登录
if (getAccessToken()) {
  const userInfo = getAuthedUser()
  state.userId = userInfo.uid
  state.username = userInfo.screenName
  state.avatarUrl = userInfo.avatarUrl ? userInfo.avatarUrl : state.avatarUrl
}

const User = {
  state,
  mutations: {
    // 更新用户信息
    UPDATE_USER_INFO(state, userInfo) {
      // 保存用户信息到缓存
      setAuthedUser(userInfo)
    },
    // 用户退出登录
    USER_LOGOUT(state) {
      state.userId = 0
      state.username = null
      state.avatarUrl = null
    }
  },
  actions: {
    // 退出登录处理操作
    USER_LOGOUT_ACTION({ commit }) {
      commit('USER_LOGOUT')
    }
  }
}

export default User
